import type { ContactFormTouchPointButtonValues, ContactFormVariant } from '../../../types/contactForm';
import { CONTACT_FORM_TOUCH_POINT_BUTTON } from '../../../types/contactForm';

export const getTrackingTouchPoint = ({
    isAskAboutPriceClicked = false,
    contactFormVariant = 'default',
}: {
    isAskAboutPriceClicked?: boolean;
    contactFormVariant?: ContactFormVariant;
} = {}): ContactFormTouchPointButtonValues => {
    if (isAskAboutPriceClicked) {
        return CONTACT_FORM_TOUCH_POINT_BUTTON.askAboutPrice;
    }

    if (contactFormVariant === 'aside') {
        return CONTACT_FORM_TOUCH_POINT_BUTTON.asideForm;
    }
    if (contactFormVariant === 'bottom') {
        return CONTACT_FORM_TOUCH_POINT_BUTTON.bottomPage;
    }
    if (contactFormVariant === 'gallery') {
        return CONTACT_FORM_TOUCH_POINT_BUTTON.galleryOpen;
    }
    if (contactFormVariant === 'sticky') {
        return CONTACT_FORM_TOUCH_POINT_BUTTON.stickyContactForm;
    }
    if (contactFormVariant === 'listingGallery') {
        return CONTACT_FORM_TOUCH_POINT_BUTTON.listingGallery;
    }

    return CONTACT_FORM_TOUCH_POINT_BUTTON.contactInfo;
};
