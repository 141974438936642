import { useClickOutside } from '@domains/shared/hooks/useClickOutside/useClickOutside';
import type { MutableRefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

interface Result {
    isFinancePrivacyPolicyVisible: boolean;
    toggleFinancePrivacyPolicyVisible: () => void;
    financePrivacyPolicyRef: MutableRefObject<HTMLDivElement | null>;
}

export const useFinanceLeadBox = (isFinanceLeadChecked: boolean): Result => {
    const [isFinancePrivacyPolicyVisible, setIsFinancePrivacyPolicyVisible] = useState(false);
    const financePrivacyPolicyRef = useRef(null);

    useEffect(() => {
        if (!isFinanceLeadChecked) {
            setIsFinancePrivacyPolicyVisible(false);
        }
    }, [isFinanceLeadChecked, setIsFinancePrivacyPolicyVisible]);

    useClickOutside(financePrivacyPolicyRef, () => {
        if (isFinancePrivacyPolicyVisible) {
            setIsFinancePrivacyPolicyVisible(false);
        }
    });

    const toggleFinancePrivacyPolicyVisible = (): void => {
        setIsFinancePrivacyPolicyVisible((isFinancePrivacyPolicyVisible) => !isFinancePrivacyPolicyVisible);
    };

    return { isFinancePrivacyPolicyVisible, toggleFinancePrivacyPolicyVisible, financePrivacyPolicyRef };
};
