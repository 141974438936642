import { GENERICS, GREY_UPDATED, PUMPKIN } from '@lib/styles/partials/colors';

import type { ContactFormVariables } from './type';

// @FIXME - this variables should be deleted and nexus theme should be used instead
export const CONTACT_FORM_WIDGET_THEME_BASE: ContactFormVariables = {
    contactFormWrapper: {
        base: {
            backgroundColor: GREY_UPDATED.x100,
        },
        formField: {
            borderColor: GREY_UPDATED.x400,
            color: GREY_UPDATED.x700,
        },
        mobileModal: {
            backgroundColor: GREY_UPDATED.x100,
        },
        note: {
            color: GREY_UPDATED.x500,
        },
        noteMore: {
            color: GREY_UPDATED.x700,
        },
        tooltip: {
            default: {
                color: GENERICS.black,
            },
            desktop: {
                color: GREY_UPDATED.x700,
                backgroundColor: GENERICS.white,
                boxShadow: '4px 4px 16px 0 rgba(117, 117, 117, 0.2)',
            },
        },
        textAreaCounter: {
            color: GREY_UPDATED.x500,
        },
        signupLabel: {
            color: GREY_UPDATED.x600,
        },
        financeLeadLabel: {
            color: GREY_UPDATED.x600,
            backgroundColor: '#e4f9ed',
        },
        withTooltip: {
            borderColor: GENERICS.white,
        },
    },
    contactModal: {
        agentContactInfoModal: {
            backgroundColor: GENERICS.white,
            agencyOtherAdsLink: {
                color: PUMPKIN.x500,
            },
        },
        contactPersonData: {
            color: GREY_UPDATED.x700,
        },
        contentSeparator: {
            borderColor: GREY_UPDATED.x300,
        },
    },
    contactPersonBanner: {
        backgroundColor: GREY_UPDATED.x100,
        color: GREY_UPDATED.x700,
        phoneIcon: {
            color: GREY_UPDATED.x500,
        },
        phoneNumberDisplay: {
            color: GREY_UPDATED.x700,
        },
    },
};
