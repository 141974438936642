import { Button } from '@domains/shared/components/Button/Button';
import { GdprNote } from '@domains/shared/components/GdprNote/GdprNote';
import { TextArea } from '@domains/shared/components/TextArea/TextArea';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

import { CONTACT_FORM_WIDGET_THEME } from '../../theme/[[[SITECODE]]]';
import { ContactFormField } from './components/ContactFormField';

const renderGridAreaByCondition = ({
    condition,
    onTrue: onConditionTrue,
    onFalse: onConditionFalse = '',
}: {
    condition: boolean | undefined;
    onTrue: string;
    onFalse?: string;
}): string => {
    return condition ? `'${onConditionTrue}'` : onConditionFalse === '' ? onConditionFalse : `'${onConditionFalse}'`;
};

const getFormGridTemplateAreas = (
    isContactFormBottom: boolean | undefined,
    isMessageSubjectDropdownDisplayed: boolean | undefined,
    isFinanceLeadCheckboxDisplayed: boolean | undefined,
): string => {
    return isContactFormBottom
        ? `
        'name email'
        ${renderGridAreaByCondition({
            condition: isMessageSubjectDropdownDisplayed,
            onTrue: 'phone messageSubject',
            onFalse: 'phone .',
        })}
        'message message'
        'note note'
        'signup .'
        ${renderGridAreaByCondition({
            condition: isFinanceLeadCheckboxDisplayed,
            onTrue: 'financeLead financeLead',
        })}
        '. submit'
        `
        : `
        'name'
        'email'
        'phone'
        ${renderGridAreaByCondition({
            condition: isMessageSubjectDropdownDisplayed,
            onTrue: 'messageSubject',
        })}
        'message'
        ${renderGridAreaByCondition({
            condition: isFinanceLeadCheckboxDisplayed,
            onTrue: 'financeLead',
        })}
        'note'
        'signup'
        'submit'
    `;
};

interface FormProps {
    isContactFormBottom?: boolean;
    isMessageSubjectDropdownDisplayed?: boolean;
    isFinanceLeadCheckboxDisplayed?: boolean;
}

const getGridAreas = ({
    isContactFormBottom,
    isMessageSubjectDropdownDisplayed,
    isFinanceLeadCheckboxDisplayed,
}: FormProps): string => {
    const areas = getFormGridTemplateAreas(
        isContactFormBottom,
        isMessageSubjectDropdownDisplayed,
        isFinanceLeadCheckboxDisplayed,
    );

    return areas
        .split('\n')
        .filter((value) => !value.includes('signup'))
        .join('\n');
};

export const Form = styled.form<FormProps>`
    display: grid;
    position: relative;
    z-index: 400;
    grid-gap: ${({ isContactFormBottom }): string => (isContactFormBottom ? '16px' : '16px 0')};
    grid-template-areas: ${getGridAreas};
    grid-template-columns: ${({ isContactFormBottom }): string => (isContactFormBottom ? '1fr 1fr' : '1fr')};
    margin-top: 16px;
`;

const StyledFormField = styled(ContactFormField)`
    color: ${CONTACT_FORM_WIDGET_THEME.contactFormWrapper.formField.color};
`;

export const NameField = styled(StyledFormField)`
    grid-area: name;
`;

export const EmailField = styled(StyledFormField)`
    grid-area: email;
`;

export const PhoneField = styled(StyledFormField)`
    grid-area: phone;
`;

export const TextAreaField = styled(StyledFormField)`
    grid-area: message;
    margin-bottom: 16px;
`;

export const MessageSubjectField = styled(StyledFormField)`
    grid-area: messageSubject;
`;

export const StyledTextArea = styled(TextArea)`
    width: 100%;
    height: 144px;
    margin-bottom: -4px;
    padding: 8px;
    color: ${CONTACT_FORM_WIDGET_THEME.contactFormWrapper.formField.color};
    resize: none;
`;

export const StyledGdprNote = styled(GdprNote)`
    grid-area: note;
`;

export const SubmitButton = styled(Button, {
    shouldForwardProp: (prop): boolean => prop !== 'isContactFormBottom',
})<{ isContactFormBottom?: boolean }>`
    grid-area: submit;
    align-self: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ isContactFormBottom }): string =>
        isContactFormBottom
            ? `
            max-width: 268px;
            margin-left: auto;
            `
            : ''}
`;

export const TextAreaCounter = styled.div`
    position: absolute;
    right: 0;
    margin-top: 8px;
    color: ${CONTACT_FORM_WIDGET_THEME.contactFormWrapper.textAreaCounter.color};
    font-size: ${theme.fontSizes['12']};
    text-align: right;
`;
