import type { ContactFormTouchPointButtonValues } from '../../../types/contactForm';
import type { MessageSubjectValues } from './getMessageBySubject';

export type GetTrackingDataReturnValue = {
    touch_point_button?: string;
    message_type?: string;
} | null;

export const getTrackingData = (
    touchPointButton?: ContactFormTouchPointButtonValues,
    messageSubject?: MessageSubjectValues,
    additionalParameters?: Record<string, unknown>,
): GetTrackingDataReturnValue => {
    if (!touchPointButton && !messageSubject) {
        return null;
    }

    return {
        ...(touchPointButton && {
            touch_point_button: touchPointButton,
        }),
        ...(messageSubject && {
            message_type: messageSubject,
        }),
        ...additionalParameters,
    };
};
