import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';

export const MESSAGE_SUBJECT = {
    moreInfo: 'more-info',
    suggestPrice: 'suggest-price',
    scheduleVisit: 'schedule-visit',
    other: 'other',
} as const;

export type MessageSubjectValues = ObjectValues<typeof MESSAGE_SUBJECT>;

export interface MessageSubjectDropdownOption {
    label: string;
    value: MessageSubjectValues;
}

const MESSAGE_SUBJECT_TRANSLATION_KEYS: Record<MessageSubjectValues, string> = {
    'more-info': 'frontend.contact-form.message-subject.more-info',
    'suggest-price': 'frontend.contact-form.message-subject.suggest-price',
    'schedule-visit': 'frontend.contact-form.message-subject.schedule-visit',
    other: 'frontend.contact-form.message-subject.other',
} as const;

export const MESSAGE_SUBJECT_OPTIONS = Object.values(MESSAGE_SUBJECT).map((value: MessageSubjectValues) => ({
    label: MESSAGE_SUBJECT_TRANSLATION_KEYS[value],
    value,
})) as Array<MessageSubjectDropdownOption>;

const MORE_INFO_MESSAGES: Record<number, string> = {
    101: 'frontend.ad.contact-form.more-info-message-sale-flat',
    102: 'frontend.ad.contact-form.more-info-message-rent-flat',
    201: 'frontend.ad.contact-form.more-info-message-sale-house',
    202: 'frontend.ad.contact-form.more-info-message-rent-house',
    302: 'frontend.ad.contact-form.more-info-message-rent-room',
    401: 'frontend.ad.contact-form.more-info-message-sale-plot',
    402: 'frontend.ad.contact-form.more-info-message-rent-plot',
    501: 'frontend.ad.contact-form.more-info-message-sale-shop',
    502: 'frontend.ad.contact-form.more-info-message-rent-shop',
    601: 'frontend.ad.contact-form.more-info-message-sale-warehouse',
    602: 'frontend.ad.contact-form.more-info-message-rent-warehouse',
    701: 'frontend.ad.contact-form.more-info-message-sale-garage',
    702: 'frontend.ad.contact-form.more-info-message-rent-garage',
};

const SCHEDULE_VISIT_MESSAGES: Record<number, string> = {
    101: 'frontend.ad.contact-form.schedule-visit-message-sale-flat',
    102: 'frontend.ad.contact-form.schedule-visit-message-rent-flat',
    201: 'frontend.ad.contact-form.schedule-visit-message-sale-house',
    202: 'frontend.ad.contact-form.schedule-visit-message-rent-house',
    302: 'frontend.ad.contact-form.schedule-visit-message-rent-room',
    401: 'frontend.ad.contact-form.schedule-visit-message-sale-plot',
    402: 'frontend.ad.contact-form.schedule-visit-message-rent-plot',
    501: 'frontend.ad.contact-form.schedule-visit-message-sale-shop',
    502: 'frontend.ad.contact-form.schedule-visit-message-rent-shop',
    601: 'frontend.ad.contact-form.schedule-visit-message-sale-warehouse',
    602: 'frontend.ad.contact-form.schedule-visit-message-rent-warehouse',
    701: 'frontend.ad.contact-form.schedule-visit-message-sale-garage',
    702: 'frontend.ad.contact-form.schedule-visit-message-rent-garage',
};

const SUGGEST_PRICE_MESSAGES: Record<number, string> = {
    101: 'frontend.ad.contact-form.suggest-price-message-sale-flat',
    102: 'frontend.ad.contact-form.suggest-price-message-rent-flat',
    201: 'frontend.ad.contact-form.suggest-price-message-sale-house',
    202: 'frontend.ad.contact-form.suggest-price-message-rent-house',
    302: 'frontend.ad.contact-form.suggest-price-message-rent-room',
    401: 'frontend.ad.contact-form.suggest-price-message-sale-plot',
    402: 'frontend.ad.contact-form.suggest-price-message-rent-plot',
    501: 'frontend.ad.contact-form.suggest-price-message-sale-shop',
    502: 'frontend.ad.contact-form.suggest-price-message-rent-shop',
    601: 'frontend.ad.contact-form.suggest-price-message-sale-warehouse',
    602: 'frontend.ad.contact-form.suggest-price-message-rent-warehouse',
    701: 'frontend.ad.contact-form.suggest-price-message-sale-garage',
    702: 'frontend.ad.contact-form.suggest-price-message-rent-garage',
};

export const getMessageBySubject = (
    categoryId: number,
    messageSubject: MessageSubjectValues | undefined,
    t: Translator,
): string => {
    let key = '';

    switch (messageSubject) {
        case MESSAGE_SUBJECT.moreInfo: {
            key = MORE_INFO_MESSAGES[categoryId];
            break;
        }
        case MESSAGE_SUBJECT.scheduleVisit: {
            key = SCHEDULE_VISIT_MESSAGES[categoryId];
            break;
        }
        case MESSAGE_SUBJECT.suggestPrice: {
            key = SUGGEST_PRICE_MESSAGES[categoryId];
            break;
        }
    }

    if (key === undefined) {
        key = 'frontend.ad.contact-form.default-message';
    }

    return key === '' ? key : t(key);
};
