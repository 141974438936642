import { Alert } from '@domains/shared/components/Alert/Alert';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';

export const StyledAlert = styled(Alert)`
    margin: 12px 0;

    @media (min-width: ${BREAKPOINT.md}) {
        margin: 0;
    }
`;
