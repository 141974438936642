import { CONTACT_FORM_WIDGET_CONFIG } from '@config/widgets/contactForm/contactFormWidgetConfig';
import { toast } from '@domains/shared/components/Toast/toast';
import { logError } from '@domains/shared/helpers/logger';
import { getStorageItemValue, setStorageItemValue } from '@domains/shared/helpers/storage';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import { useTracking } from '@lib/tracking/useTracking';
import type { MessageDetailTrackingParam } from '@widgets/contactForm/types/messageDetailTrackingParam';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import type { UseFormHandleSubmit, UseFormReset } from 'react-hook-form';
import { useMutation } from 'urql';

import type { FormPrefillValues, FormValues } from '../constants/formValues';
import { FORM_FIELD } from '../constants/formValues';
import { PREFILL_FORM_DATA_STORAGE_KEY } from '../constants/prefillFormDataStorageKey';
import { SEND_MESSAGE_MUTATION } from '../graphql/mutations/SendMessageMutation';
import { MESSAGE_SUBJECT_OPTIONS } from '../helpers/getMessageBySubject';
import type { GetTrackingDataReturnValue } from '../helpers/getTrackingData';
import { hasPrefillFormDataChanged } from '../helpers/hasPrefillFormDataChanged';

interface Result {
    onFormSubmit: () => Promise<void>;
    fetching: boolean;
    lastSuccessfulSubmit?: number;
}

interface Arguments {
    handleSubmit: UseFormHandleSubmit<FormValues>;
    reset: UseFormReset<FormValues>;
    trackingData: GetTrackingDataReturnValue;
    messageDetail: MessageDetailTrackingParam;
    shouldDisplayPredefinedContactMessages: boolean;
    advertId: number;
    cityName?: string;
    isModal: boolean;
    defaultMessage: string;
    setCharacterCount: Dispatch<SetStateAction<number>>;
    onSubmit?(): void;
    additionalTrackingData?: Record<string, unknown>;
}

interface PrefillFormArguments {
    shouldPrefillFormWithRecentData: boolean;
    name: string;
    email: string;
    phone: string;
    defaultMessage: string;
    isModal: boolean;
    contactFormPrefillData: FormPrefillValues;
    reset: UseFormReset<FormValues>;
}

const prefillForm = ({
    shouldPrefillFormWithRecentData,
    name,
    email,
    phone,
    defaultMessage,
    isModal,
    contactFormPrefillData,
    reset,
}: PrefillFormArguments): void => {
    if (shouldPrefillFormWithRecentData) {
        const newFormData = { name, email, phone };
        const shouldUpdatePrefillData = hasPrefillFormDataChanged({
            contactFormPrefillData,
            newFormData,
        });

        if (shouldUpdatePrefillData) {
            setStorageItemValue(PREFILL_FORM_DATA_STORAGE_KEY, newFormData);
        }

        if (!isModal) {
            // Note: To prevent message input from being cleared on form submit, include defaultMessage in the form data.
            reset(shouldUpdatePrefillData ? { ...newFormData, [FORM_FIELD.text]: defaultMessage } : undefined);
        }

        return;
    }

    if (!isModal) {
        reset();
    }
};

export const useSubmitForm = ({
    handleSubmit,
    reset,
    trackingData,
    shouldDisplayPredefinedContactMessages,
    advertId,
    cityName,
    isModal,
    defaultMessage,
    setCharacterCount,
    onSubmit,
    messageDetail,
}: Arguments): Result => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const [{ fetching }, sendMessage] = useMutation(SEND_MESSAGE_MUTATION);
    const { isAwaitingUserData, isUserLoggedIn, user } = useLazyUser();

    const [lastSuccessfulSubmit, setLastSuccessfulSubmit] = useState<number | undefined>();

    const shouldPrefillFormWithRecentData = !isAwaitingUserData && !isUserLoggedIn;

    const contactFormPrefillData = getStorageItemValue(PREFILL_FORM_DATA_STORAGE_KEY) as FormPrefillValues;

    const onFormSubmit = handleSubmit(
        (formData: FormValues) => {
            const { text, email, name, phone, messageSubject, financeLead } = formData;
            const userEmail = email || user?.email || null;
            const textWithSubjectAsPrefix = `[${t(messageSubject?.label || MESSAGE_SUBJECT_OPTIONS[0].label)}] ${text}`;
            const additionalLeadCollectorVariables = financeLead
                ? {
                      utm: CONTACT_FORM_WIDGET_CONFIG.utm,
                      leadFormType: 'other',
                      leadFormTool: 'checkbox',
                  }
                : undefined;

            trackEvent('reply_message_click', trackingData);
            sendMessage({
                text: shouldDisplayPredefinedContactMessages ? textWithSubjectAsPrefix : text,
                email: userEmail,
                name,
                phone,
                advertId,
                financeLead,
                city: cityName,
                ...additionalLeadCollectorVariables,
            })
                .then(({ error, data }) => {
                    if (error) {
                        toast.error(t('frontend.ad.contact-form.submit-error'));
                        logError('Unable to send Ad Contact Form.', { error });
                        trackEvent('reply_message_sent_error', trackingData);

                        if (financeLead) {
                            trackEvent('finance_lead_form_error', trackingData);
                        }

                        return;
                    }

                    if (data.sendMessage.__typename !== 'MessageResponse') {
                        return;
                    }

                    trackEvent('reply_message_sent', {
                        ...trackingData,
                        message_detail: messageDetail,
                        finance_lead_consent: financeLead ? 'tick' : 'untick',
                    });
                    toast.success(t('frontend.ad.contact-form.submit-success'), {
                        dataCy: 'contact-form.success-toast',
                    });

                    if (financeLead) {
                        data.sendMessage.financeLeadResponse?.success
                            ? trackEvent('finance_lead_form_sent', trackingData)
                            : trackEvent('finance_lead_form_error', trackingData);
                    }

                    prefillForm({
                        shouldPrefillFormWithRecentData,
                        name,
                        email,
                        phone,
                        defaultMessage,
                        isModal,
                        contactFormPrefillData,
                        reset,
                    });

                    setCharacterCount(defaultMessage.length);
                    setLastSuccessfulSubmit(Date.now());
                    onSubmit?.();
                })
                .catch((error) => {
                    logError('Failed sending the message', { error });
                    toast.error(t('frontend.global.toast.error'));
                    trackEvent('reply_message_sent_error', trackingData);

                    if (financeLead) {
                        trackEvent('finance_lead_form_error', trackingData);
                    }
                });
        },
        () => {
            trackEvent('reply_message_click', trackingData);
            trackEvent('reply_message_click_error', trackingData);
        },
    );

    return { fetching: fetching || !!isUserLoggedIn, onFormSubmit, lastSuccessfulSubmit };
};
