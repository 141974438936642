import type { ContactFormAdvertData } from '@type/ad/contactForm';

import type { SharedFormProps } from '../ContactFormWrapper/types';

export type ContactFormVariant = 'default' | 'bottom' | 'gallery' | 'sticky' | 'aside' | 'listingGallery';

export type ContactFormProps = SharedFormProps &
    ContactFormAdvertData & {
        askAboutPrice?: boolean;
        askForMissingInfo?: boolean;
        isModal?: boolean;
        shouldListenForSuggestPriceEvent?: boolean;
        suggestPrice?: boolean;
        onSubmit?(): void;
        additionalTrackingData?: Record<string, unknown>;
    };

export const CONTACT_FORM_TOUCH_POINT_BUTTON = {
    asideForm: 'aside_form',
    askAboutPrice: 'ask_about_price',
    bottomPage: 'bottom_page',
    countryCode: 'country_code',
    contactInfo: 'contact_info',
    galleryOpen: 'gallery_open',
    phoneNumber: 'phone_number',
    stickyContactForm: 'sticky_contact_form',
    listingGallery: 'listing_gallery',
} as const;

export type ContactFormTouchPointButtonValues = ObjectValues<typeof CONTACT_FORM_TOUCH_POINT_BUTTON>;
