interface FormPrefillValues {
    name: string;
    email: string;
    phone: string;
}

export const hasPrefillFormDataChanged = ({
    contactFormPrefillData,
    newFormData,
}: {
    contactFormPrefillData: FormPrefillValues | undefined;
    newFormData: FormPrefillValues;
}): boolean => {
    if (!contactFormPrefillData) {
        return true;
    }

    const { name, email, phone } = contactFormPrefillData;
    const { name: newName, email: newEmail, phone: newPhone } = newFormData;

    return `${name}${email}${phone}` !== `${newName}${newEmail}${newPhone}`;
};
