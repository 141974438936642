import type { ContactFormWidgetConfig } from '@config/widgets/contactForm/type';

export * from '@config/widgets/contactForm/type';

export const CONTACT_FORM_WIDGET_CONFIG: ContactFormWidgetConfig = {
    utm: {
        source: 'otodom',
        medium: 'contactForm',
        campaign: 'checkbox',
    },
};
