import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LINE_HEIGHT, SIZE, WEIGHT } from '@lib/styles/partials/typography';
import { useTheme } from '@nexus/lib-react/dist/theme/emotionUtils';
import type { CSSProperties, JSX } from 'react';

import type { LayoutVariant } from './Alert';

const BaseWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px;
    border: 1px solid;
    border-radius: 4px;
`;

const CONTENT_WRAPPER_STYLES_PER_VARIANT: Record<LayoutVariant, CSSProperties> = {
    columnDesktop: {
        flexDirection: 'column',
        gap: '16px',
    },
    rowDesktop: {
        flexDirection: 'row',
        gap: '0',
    },
};

export const ContentWrapper = styled.div<{ layoutVariant: LayoutVariant }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @media (min-width: ${BREAKPOINT.sm}) {
        flex-direction: row;
        align-items: center;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        flex-direction: ${({ layoutVariant }): CSSProperties['flexDirection'] =>
            CONTENT_WRAPPER_STYLES_PER_VARIANT[layoutVariant].flexDirection};
        gap: ${({ layoutVariant }): CSSProperties['gap'] => CONTENT_WRAPPER_STYLES_PER_VARIANT[layoutVariant].gap};
    }
`;

export const LabelWrapper = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const IconWrapper = styled.div`
    flex-shrink: 0;
    margin-right: 8px;
`;

const ACTION_WRAPPER_STYLES_PER_VARIANT: Record<LayoutVariant, CSSProperties> = {
    columnDesktop: {
        width: '100%',
        marginLeft: '0',
        paddingLeft: '0',
    },
    rowDesktop: {
        width: 'auto',
        marginLeft: 'auto',
        paddingLeft: '16px',
    },
};

export const ActionWrapper = styled.div<{ layoutVariant: LayoutVariant }>`
    display: flex;
    flex-shrink: 0;
    align-self: stretch;
    margin-top: 16px;
    margin-left: 0;

    @media (min-width: ${BREAKPOINT.xs}) {
        align-self: initial;
    }

    @media (min-width: ${BREAKPOINT.sm}) {
        align-self: center;
        margin-top: -8px;
        margin-bottom: -8px;
        margin-left: auto;
        padding-left: 16px;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        width: ${({ layoutVariant }): CSSProperties['width'] => ACTION_WRAPPER_STYLES_PER_VARIANT[layoutVariant].width};
        margin-left: ${({ layoutVariant }): CSSProperties['marginLeft'] =>
            ACTION_WRAPPER_STYLES_PER_VARIANT[layoutVariant].marginLeft};
        padding-left: ${({ layoutVariant }): CSSProperties['paddingLeft'] =>
            ACTION_WRAPPER_STYLES_PER_VARIANT[layoutVariant].paddingLeft};
    }
`;

export const ErrorWrapper = styled(BaseWrapper)`
    border-color: ${({ theme }): string => theme.deprecated.domains.shared.alert.error.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.domains.shared.alert.error.backgroundColor};
`;

export const InfoWrapper = styled(BaseWrapper)`
    border-color: ${({ theme }): string => theme.deprecated.domains.shared.alert.info.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.domains.shared.alert.info.backgroundColor};
`;

export const SuccessWrapper = styled(BaseWrapper)`
    border-color: ${({ theme }): string => theme.deprecated.domains.shared.alert.success.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.domains.shared.alert.success.backgroundColor};
`;

export const WarningWrapper = styled(BaseWrapper)`
    border-color: ${({ theme }): string => theme.deprecated.domains.shared.alert.warning.borderColor};
    background-color: ${({ theme }): string => theme.deprecated.domains.shared.alert.warning.backgroundColor};
`;

export const Label = styled.p`
    margin: 0;
    color: ${({ theme }): string => theme.deprecated.domains.shared.alert.label.color};
    font-size: ${SIZE.p3};
    font-weight: ${WEIGHT.semibold};
    line-height: ${LINE_HEIGHT.x1};
`;

export const ErrorIcon = (): JSX.Element => {
    const theme = useTheme();

    return <Icon icon={faExclamationCircle} color={theme.deprecated.domains.shared.alert.error.icon.color} size="lg" />;
};

export const InfoIcon = (): JSX.Element => {
    const theme = useTheme();

    return <Icon icon={faInfoCircle} color={theme.deprecated.domains.shared.alert.info.icon.color} size="lg" />;
};

export const SuccessIcon = (): JSX.Element => {
    const theme = useTheme();

    return <Icon icon={faCheckCircle} color={theme.deprecated.domains.shared.alert.success.icon.color} size="lg" />;
};

export const WarningIcon = (): JSX.Element => {
    const theme = useTheme();

    return (
        <Icon icon={faExclamationTriangle} color={theme.deprecated.domains.shared.alert.warning.icon.color} size="lg" />
    );
};

export const CloseButton = styled.button`
    align-self: flex-start;
    margin: 0 8px;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;

    &:active,
    &:focus,
    &:hover {
        opacity: 0.8;
    }

    @media (min-width: ${BREAKPOINT.sm}) {
        align-self: initial;
        margin-left: 56px;
    }
`;

export const CloseIcon = (): JSX.Element => {
    const theme = useTheme();

    return <Icon icon={faTimes} size="lg" color={theme.deprecated.domains.shared.alert.closeIcon.color} />;
};
