import { SITE_CONFIG } from '@config/siteConfig';
import { Checkbox } from '@domains/shared/components/Checkbox/Checkbox';
import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { replacePlaceholders } from '@domains/shared/helpers/replacePlaceholders';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useMemo } from 'react';
import type { UseFormRegister } from 'react-hook-form';

import type { ContactFormVariant } from '../../../../types/contactForm';
import type { FormValues } from '../../constants/formValues';
import { FORM_FIELD } from '../../constants/formValues';
import { getFormId } from '../../helpers/getFormId';
import {
    FinanceLabel,
    FinanceLeadBoxWrapper,
    FinanceLeadContainer,
    FinanceLeadField,
    FinanceLeadHeader,
    InlineGdpr,
    PrivacyPolicyLink,
    PrivacyPolicyParagraph,
    Tooltip,
    WithTooltip,
} from './FinanceLeadBox.theme';
import { useFinanceLeadBox } from './hooks/useFinanceLeadBox';

interface Props {
    shouldDisplayGdprInline: boolean;
    formVariant: ContactFormVariant;
    register: UseFormRegister<FormValues>;
    isFinanceLeadChecked: boolean;
}

export const FinanceLeadBox = ({
    shouldDisplayGdprInline,
    formVariant,
    register,
    isFinanceLeadChecked,
}: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const { isFinancePrivacyPolicyVisible, toggleFinancePrivacyPolicyVisible, financePrivacyPolicyRef } =
        useFinanceLeadBox(isFinanceLeadChecked);

    const formId = getFormId(formVariant);
    const financeBoxIdModified = `finance-lead-checkbox-${shouldDisplayGdprInline}${formId}`;

    const isContactFormBottom = formVariant === 'bottom';

    const formattedFinancePrivacyPolicy = useMemo(() => {
        const financePrivacyPolicyKey = 'frontend.ad.contact-form.finance-privacy-policy';

        return replacePlaceholders(t(financePrivacyPolicyKey), (text) => {
            if (text.includes('%a%')) {
                const linkLabel = text.replace(/%a%/g, '');

                return (
                    <PrivacyPolicyLink
                        href={SITE_CONFIG.privacyPolicyLink}
                        target="_blank"
                        rel="noreferrer"
                        shouldDisplayGdprInline={shouldDisplayGdprInline}
                    >
                        {linkLabel}
                    </PrivacyPolicyLink>
                );
            }

            if (text.includes('%li%')) {
                return <li>{text.replace(/%li%/g, '')}</li>;
            }

            return (
                <PrivacyPolicyParagraph>
                    <b>{text}</b>
                </PrivacyPolicyParagraph>
            );
        });
    }, [t, shouldDisplayGdprInline]);

    return (
        <FinanceLeadBoxWrapper ref={financePrivacyPolicyRef}>
            <FinanceLeadContainer>
                <FinanceLeadHeader>{t('frontend.ad.contact-form.title-finance-lead-remt-615')}</FinanceLeadHeader>
                <FinanceLeadField>
                    <Checkbox
                        onClick={(event): void => {
                            toggleFinancePrivacyPolicyVisible();
                            trackEvent('finance_lead_consent', {
                                touch_point_button: (event?.target as HTMLInputElement).checked ? 'tick' : 'untick',
                            });
                        }}
                        id={financeBoxIdModified}
                        {...register(FORM_FIELD.financeLead)}
                        aria-label={t('frontend.ad.contact-form.allow-send-finance-lead')}
                        data-cy="contact-form.finance-lead-checkbox"
                    />
                    <FinanceLabel htmlFor={financeBoxIdModified}>
                        {replacePlaceholders(t('frontend.ad.contact-form.allow-send-finance-lead-remt-615'), (text) => (
                            <b>{text}</b>
                        ))}
                        <Icon
                            icon={faQuestionCircle}
                            onClick={(event): void => {
                                event.preventDefault();
                                toggleFinancePrivacyPolicyVisible();
                            }}
                        />
                    </FinanceLabel>
                </FinanceLeadField>
            </FinanceLeadContainer>
            {isFinancePrivacyPolicyVisible ? (
                <>
                    {shouldDisplayGdprInline || isContactFormBottom ? (
                        <InlineGdpr>{formattedFinancePrivacyPolicy}</InlineGdpr>
                    ) : (
                        <WithTooltip>
                            <Tooltip role="alert" data-cy="contact-form.finance-privacy-policy.tooltip" isFixedWidth>
                                {formattedFinancePrivacyPolicy}
                            </Tooltip>
                        </WithTooltip>
                    )}
                </>
            ) : null}
        </FinanceLeadBoxWrapper>
    );
};
