import type { TrackEvent } from '@lib/tracking/types';
import type { FocusEvent } from 'react';
import type { ChangeHandler } from 'react-hook-form';
import type { FieldErrors } from 'react-hook-form/dist/types/errors';
import type { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

import type { GetTrackingDataReturnValue } from './getTrackingData';

interface TrackInputEventArguments {
    errors: FieldErrors;
    registerValue: UseFormRegisterReturn;
    trackingData: GetTrackingDataReturnValue;
    trackEventFieldName?: string;
    trackEvent: TrackEvent;
}

type TrackInputEventReturnValue = UseFormRegisterReturn & { onFocus: () => void };

export const trackInputEvent = ({
    errors,
    registerValue,
    trackingData,
    trackEventFieldName,
    trackEvent,
}: TrackInputEventArguments): TrackInputEventReturnValue => {
    const { name, onBlur: onRegisterBlur } = registerValue;

    const onFocus = (): void => {
        trackEvent(`reply_message_${trackEventFieldName || name}_start`, trackingData);
    };
    const onBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>): ReturnType<ChangeHandler> => {
        const returnValue = onRegisterBlur(event);

        returnValue.then(() => {
            const isValid = !errors?.[name]?.message;

            trackEvent(`reply_message_${trackEventFieldName || name}_${isValid ? 'valid' : 'error'}`, trackingData);
        });

        return returnValue;
    };

    return { ...registerValue, name, onBlur, onFocus };
};
