import type { FC, JSX } from 'react';

import { ContactForm } from '../core/ContactForm';
import { getFormId } from '../core/ContactForm/helpers/getFormId';
import type { ContactFormProps } from '../types/contactForm';
import { Container } from './ContactFormWrapper.theme';

interface ContactFormWrapperProps {
    afterContactForm?: JSX.Element | null;
    beforeContactForm?: JSX.Element | null;
}

type Props = ContactFormProps & ContactFormWrapperProps;

export const ContactFormWrapper: FC<Props> = ({
    afterContactForm,
    beforeContactForm,
    variant = 'default',
    ...restProps
}) => {
    const formId = getFormId(variant);

    return (
        <Container data-cy={`contact-form${formId}`}>
            {beforeContactForm}
            <ContactForm variant={variant} {...restProps} />
            {afterContactForm}
        </Container>
    );
};

export default ContactFormWrapper;
