import { FieldError } from '@domains/shared/components/FieldError/FieldError';
import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';

interface Props extends PropsWithChildren {
    error?: string | true;
    className?: string;
}

export const ContactFormField: FC<Props> = ({ children, error, className }) => {
    const errorElement = useMemo(() => <FieldError dataCy="contact-form.field-error" error={error} />, [error]);

    return (
        <div className={className}>
            {children}
            {errorElement}
        </div>
    );
};
