import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import { CATEGORY_ID } from '@domains/shared/types/categoryId';
import type { AdInformation, ExtendedAdInformation } from '@type/ad/adDetails';
import type { AdvertLinks } from '@type/ad/advertLinks';

const EXTENDED_CATEGORY_ID = {
    ...CATEGORY_ID,
    roomForRent: 302,
    plotForSale: 401,
    plotForRent: 402,
    warehouseForSale: 601,
    warehouseForRent: 602,
    garageForSale: 701,
    garageForRent: 702,
};

const MESSAGE_PREFIXES: Record<number, string> = {
    [EXTENDED_CATEGORY_ID.apartmentsForSale]: 'frontend.ad.contact-form.missing-information-sale-flat.first-part',
    [EXTENDED_CATEGORY_ID.apartmentsForRent]: 'frontend.ad.contact-form.missing-information-rent-flat.first-part',
    [EXTENDED_CATEGORY_ID.housesForSale]: 'frontend.ad.contact-form.missing-information-sale-house.first-part',
    [EXTENDED_CATEGORY_ID.housesForRent]: 'frontend.ad.contact-form.missing-information-rent-house.first-part',
    [EXTENDED_CATEGORY_ID.roomForRent]: 'frontend.ad.contact-form.missing-information-rent-room.first-part',
    [EXTENDED_CATEGORY_ID.plotForSale]: 'frontend.ad.contact-form.missing-information-sale-plot.first-part',
    [EXTENDED_CATEGORY_ID.plotForRent]: 'frontend.ad.contact-form.missing-information-rent-plot.first-part',
    [EXTENDED_CATEGORY_ID.commercialsForSale]: 'frontend.ad.contact-form.missing-information-sale-shop.first-part',
    [EXTENDED_CATEGORY_ID.commercialsForRent]: 'frontend.ad.contact-form.missing-information-rent-shop.first-part',
    [EXTENDED_CATEGORY_ID.warehouseForSale]: 'frontend.ad.contact-form.missing-information-sale-warehouse.first-part',
    [EXTENDED_CATEGORY_ID.warehouseForRent]: 'frontend.ad.contact-form.missing-information-rent-warehouse.first-part',
    [EXTENDED_CATEGORY_ID.garageForSale]: 'frontend.ad.contact-form.missing-information-sale-garage.first-part',
    [EXTENDED_CATEGORY_ID.garageForRent]: 'frontend.ad.contact-form.missing-information-rent-garage.first-part',
};

export const getAskForMoreInfoMessage = ({
    topInformationData,
    adLinks,
    translator: t,
    categoryId,
}: {
    topInformationData: AdInformation[] | ExtendedAdInformation[];
    adLinks?: AdvertLinks;
    translator: Translator;
    categoryId: number;
}): string => {
    const emptyTopInformation = topInformationData.filter((item) => item.values.length === 0);
    const itemTranslationPrefix = 'frontend.ad.table-information-label-';

    const { localPlanUrl, videoUrl, view3dUrl, walkaroundUrl } = adLinks || {};
    const isWithAdLinks = !!(localPlanUrl || videoUrl || view3dUrl || walkaroundUrl);

    const filteredItems = emptyTopInformation.filter((item) => !(item.label === 'remote_services' && isWithAdLinks));

    const itemsOnRequestLabels = filteredItems.map(({ label }) => t(`${itemTranslationPrefix}${label}`).toLowerCase());

    const messagePrefix = MESSAGE_PREFIXES[categoryId] || 'frontend.ad.contact-form.missing-information.first-part';

    return (
        t(messagePrefix) +
        itemsOnRequestLabels.join(', ') +
        t('frontend.ad.contact-form.missing-information.second-part')
    );
};
