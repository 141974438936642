import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { getLocaleFromLangCode } from '@domains/shared/helpers/getLocaleFromLangCode';
import { useLocalStorageCache } from '@domains/shared/hooks/useLocalStorageCache/useLocalStorageCache';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Locale } from '@lib/i18n/types/locale';
import type { JSX } from 'react';
import { memo, useEffect, useState } from 'react';

import { StyledAlert } from './LastContact.theme';

interface Props {
    adId: number;
    lastSuccessfulSubmit?: number;
}

interface StorageAdsContact {
    [key: string]: number;
}
const checkIsStorageAdsContact = (value: unknown): value is StorageAdsContact => {
    if (typeof value !== 'object' || value === null) {
        return false;
    }

    return Object.values(value).every((item) => typeof item === 'number');
};

const { tz } = getPublicEnvConfig();

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;
const MILLISECONDS_IN_DAY = 86400 * 1000;
const calcCalendarDaysDiff = (lang: Locale, timestamp: number): [number, string] => {
    // here the thing is that we shouldn't calculate exact days like 24 hours but days in the calendar,
    // e.g. a user sends the message at 23:00, then at 1:00 it is yesterday, but 24 hours haven't passed
    const currentDate = new Date();
    const messageDate = new Date(timestamp);
    let days = Math.floor((currentDate.getTime() - timestamp) / MILLISECONDS_IN_DAY);
    const currentTimeInSec = currentDate.getHours() * SECONDS_IN_HOUR + currentDate.getMinutes() * SECONDS_IN_MINUTE;
    const messageTimeInSec = messageDate.getHours() * SECONDS_IN_HOUR + messageDate.getMinutes() * SECONDS_IN_MINUTE;

    if (messageTimeInSec > currentTimeInSec) {
        // add one day for calendar days diff, e.g. message sent: `18:00`, current time: `09:00`
        days += 1;
    }

    return [days, messageDate.toLocaleDateString(getLocaleFromLangCode(lang), { timeZone: tz })];
};

const getTranslation = (lang: Locale, t: Translator, lastAdContactTimestamp: number): string => {
    const [daysElapsed, messageFormattedDate] = calcCalendarDaysDiff(lang, lastAdContactTimestamp);

    if (daysElapsed > 30) {
        return t('frontend.ad.contact-form.notification-on-date', { date: messageFormattedDate });
    }
    if (daysElapsed < 1) {
        return t('frontend.ad.contact-form.notification-today');
    }
    if (daysElapsed < 2) {
        return t('frontend.ad.contact-form.notification-yesterday');
    }

    return t('frontend.ad.contact-form.notification-n-days-ago', { days: String(daysElapsed) });
};

const LastContactBase = ({ adId, lastSuccessfulSubmit }: Props): JSX.Element | null => {
    const { lang } = useSiteSettings();
    const [t] = useTranslations();
    const [lastAdContactTimestamp, setLastAdContactTimestamp] = useState<number | undefined>();
    const [getStorageValue, setStorageValue] = useLocalStorageCache('adContact');

    useEffect(() => {
        const cachedValue = getStorageValue();
        const allContacts = checkIsStorageAdsContact(cachedValue) ? cachedValue : {};
        const stringAdId = String(adId);

        if (lastSuccessfulSubmit) {
            allContacts[stringAdId] = lastSuccessfulSubmit;
            setStorageValue(allContacts);
        }

        const lastAdContact: number | undefined = allContacts[stringAdId];

        setLastAdContactTimestamp(lastAdContact);
    }, [adId, getStorageValue, lastSuccessfulSubmit, setStorageValue]);

    if (!lastAdContactTimestamp) {
        return null;
    }

    return <StyledAlert dataCy="alert-wrapper" label={getTranslation(lang, t, lastAdContactTimestamp)} />;
};

export const LastContact = memo(LastContactBase);
