import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { StyledComponent } from '@emotion/styled';
import type { FC, HTMLAttributes, ReactNode } from 'react';

import {
    ActionWrapper,
    CloseButton,
    CloseIcon,
    ContentWrapper,
    ErrorIcon,
    ErrorWrapper,
    IconWrapper,
    InfoIcon,
    InfoWrapper,
    Label,
    LabelWrapper,
    SuccessIcon,
    SuccessWrapper,
    WarningIcon,
    WarningWrapper,
} from './Alert.theme';

export type AlertVariant = 'error' | 'info' | 'success' | 'warning';
export type LayoutVariant = 'columnDesktop' | 'rowDesktop';
interface Props {
    dataCy?: string;
    variant?: AlertVariant;
    icon?: ReactNode;
    label: ReactNode;
    className?: string;
    action?: ReactNode;
    onClose?: () => void;
    layoutVariant?: LayoutVariant;
}

const wrapperFactory = (variant: AlertVariant): StyledComponent<HTMLAttributes<HTMLDivElement>> => {
    switch (variant) {
        case 'success': {
            return SuccessWrapper;
        }
        case 'warning': {
            return WarningWrapper;
        }
        case 'error': {
            return ErrorWrapper;
        }
        default: {
            return InfoWrapper;
        }
    }
};

const iconFactory = (variant: AlertVariant): ReactNode => {
    switch (variant) {
        case 'success': {
            return <SuccessIcon />;
        }
        case 'warning': {
            return <WarningIcon />;
        }
        case 'error': {
            return <ErrorIcon />;
        }
        default: {
            return <InfoIcon />;
        }
    }
};

export const Alert: FC<Props> = ({
    variant = 'info',
    icon,
    className,
    label,
    action,
    onClose,
    dataCy,
    layoutVariant = 'rowDesktop',
}) => {
    const [t] = useTranslations();
    const Wrapper = wrapperFactory(variant);
    const defaultVariantIcon = iconFactory(variant);
    const isClosable = !!onClose;

    return (
        <Wrapper className={className} data-cy={dataCy} role="alert">
            <ContentWrapper layoutVariant={layoutVariant}>
                <LabelWrapper>
                    <IconWrapper>{icon || defaultVariantIcon}</IconWrapper>
                    <Label>{label}</Label>
                </LabelWrapper>
                {action ? <ActionWrapper layoutVariant={layoutVariant}>{action}</ActionWrapper> : null}
            </ContentWrapper>
            {isClosable ? (
                <CloseButton type="button" onClick={onClose} aria-label={t('frontend.shared.alert.close-btn')}>
                    <CloseIcon />
                </CloseButton>
            ) : null}
        </Wrapper>
    );
};
