import type { MessageSubjectDropdownOption } from '../helpers/getMessageBySubject';

export const FORM_FIELD = {
    name: 'name',
    email: 'email',
    phone: 'phone',
    text: 'text',
    messageSubject: 'messageSubject',
    financeLead: 'financeLead',
} as const;

export interface FormValues {
    [FORM_FIELD.name]: string;
    [FORM_FIELD.email]: string;
    [FORM_FIELD.phone]: string;
    [FORM_FIELD.text]: string;
    [FORM_FIELD.messageSubject]?: MessageSubjectDropdownOption | null;
    [FORM_FIELD.financeLead]: boolean;
}

export interface FormPrefillValues {
    [FORM_FIELD.name]: string;
    [FORM_FIELD.email]: string;
    [FORM_FIELD.phone]: string;
}
