import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { OpenDay } from '@type/ad/openDay';

interface Params {
    advertId: string;
    categoryId: number;
    openDay: OpenDay | null;
    t: Translator;
}

const MESSAGES: Record<number, string> = {
    11: 'frontend.ad.contact-form.default-message-development',
    101: 'frontend.ad.contact-form.default-message-sale-flat',
    102: 'frontend.ad.contact-form.default-message-rent-flat',
    201: 'frontend.ad.contact-form.default-message-sale-house',
    202: 'frontend.ad.contact-form.default-message-rent-house',
    302: 'frontend.ad.contact-form.default-message-rent-room',
    401: 'frontend.ad.contact-form.default-message-sale-plot',
    402: 'frontend.ad.contact-form.default-message-rent-plot',
    501: 'frontend.ad.contact-form.default-message-sale-shop',
    502: 'frontend.ad.contact-form.default-message-rent-shop',
    601: 'frontend.ad.contact-form.default-message-sale-warehouse',
    602: 'frontend.ad.contact-form.default-message-rent-warehouse',
    701: 'frontend.ad.contact-form.default-message-sale-garage',
    702: 'frontend.ad.contact-form.default-message-rent-garage',
};

export const getDefaultMessage = ({ advertId, categoryId = 0, openDay, t }: Params): string => {
    if (openDay?.date) {
        return t('frontend.ad.contact-form.default-message-open-days');
    }

    let message = MESSAGES[categoryId];

    if (!message) {
        message = 'frontend.ad.contact-form.default-message';
    }

    return t(message, { advertId });
};
