import { SITE_CONFIG } from '@config/siteConfig';
import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { Dropdown } from '@domains/shared/components/Dropdown/Dropdown';
import { transformGdprDetails } from '@domains/shared/components/GdprNote/helpers/transformGdprDetails';
import { Input } from '@domains/shared/components/Input/Input';
import { createPhoneInputRegisterValidators } from '@domains/shared/components/PhoneInput/createPhoneInputRegisterValidators';
import type { PhoneInputProps } from '@domains/shared/components/PhoneInput/PhoneInput';
import { PhoneInput } from '@domains/shared/components/PhoneInput/PhoneInput';
import { EMAIL_REGULAR_EXPRESSION } from '@domains/shared/consts/regularExpressions';
import { getStorageItemValue } from '@domains/shared/helpers/storage';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { listenForPlatformEvent, unmountPlatformEvent } from '@lib/events';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import { useTracking } from '@lib/tracking/useTracking';
import { AD_PAGE_EVENT } from '@type/ad/events';
import type { MessageDetailTrackingParam } from '@widgets/contactForm/types/messageDetailTrackingParam';
import type { FormEvent, JSX } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { ControllerRenderProps } from 'react-hook-form';
import { Controller, useForm, useWatch } from 'react-hook-form';
import type { SingleValue } from 'react-select';

import type { ContactFormProps } from '../../types/contactForm';
import { CONTACT_FORM_TOUCH_POINT_BUTTON } from '../../types/contactForm';
import { FinanceLeadBox } from './components/FinanceLeadBox';
import { LastContact } from './components/LastContact';
import type { FormPrefillValues, FormValues } from './constants/formValues';
import { FORM_FIELD } from './constants/formValues';
import { PREFILL_FORM_DATA_STORAGE_KEY } from './constants/prefillFormDataStorageKey';
import {
    EmailField,
    Form,
    MessageSubjectField,
    NameField,
    PhoneField,
    StyledGdprNote,
    StyledTextArea,
    SubmitButton,
    TextAreaCounter,
    TextAreaField,
} from './ContactForm.theme';
import { checkIsCategoryToSell } from './helpers/checkIsCategoryToSell';
import { getAskForMoreInfoMessage } from './helpers/getAskForMoreInfoMessage';
import { getDefaultMessage } from './helpers/getDefaultMessage';
import type { MessageSubjectDropdownOption } from './helpers/getMessageBySubject';
import { getMessageBySubject, MESSAGE_SUBJECT, MESSAGE_SUBJECT_OPTIONS } from './helpers/getMessageBySubject';
import { getTrackingData } from './helpers/getTrackingData';
import { getTrackingTouchPoint } from './helpers/getTrackingTouchPoint';
import { trackInputEvent } from './helpers/trackInputEvent';
import { useSubmitForm } from './hooks/useSubmitForm';

const EMPTY_ERROR = { message: undefined };

const TEXT_AREA_MAX_LENGTH = 2000;

const ERROR_DETAIL = {
    nameRequired: 'frontend.ad.contact-form.name-required-error',
    emailRequired: 'frontend.ad.contact-form.email-required-error',
    textRequired: 'frontend.ad.contact-form.text-required-error',
    email: 'frontend.ad.contact-form.email-error',
    messageSubjectRequired: 'frontend.ad.contact-form.message-subject-required-error',
} as const;

export const ContactForm = ({
    additionalInformation,
    advertCategoryId,
    advertId,
    advertSource,
    cityName,
    links,
    openDay,
    topInformation,
    askAboutPrice,
    askForMissingInfo,
    isModal = false,
    shouldDisplayGdprInline = false,
    shouldDisplayPredefinedContactMessages = false,
    shouldListenForSuggestPriceEvent = false,
    suggestPrice,
    variant = 'default',
    onSubmit,
    additionalTrackingData = {},
}: ContactFormProps): JSX.Element => {
    const [t] = useTranslations();
    const { featureFlags } = useSiteSettings();
    const { trackEvent } = useTracking();
    const { isAwaitingUserData, isUserLoggedIn, user } = useLazyUser();
    const messageDetailParam = useRef<MessageDetailTrackingParam>('default_predefined_message');

    const shouldDisplayFinanceLeadCheckbox =
        checkIsCategoryToSell(advertCategoryId) && !!featureFlags.isFinanceCheckboxEnabled && !askAboutPrice;

    const shouldPrefillFormWithRecentData = !isAwaitingUserData && !isUserLoggedIn;
    const contactFormPrefillData = getStorageItemValue(PREFILL_FORM_DATA_STORAGE_KEY) as FormPrefillValues;

    const isContactFormBottom = variant === 'bottom';

    const askForInfoMessage = useMemo(() => {
        const askForMoreInfoMessageValues = additionalInformation
            ? [...topInformation, ...additionalInformation]
            : topInformation;

        return getAskForMoreInfoMessage({
            topInformationData: askForMoreInfoMessageValues,
            adLinks: links,
            translator: t,
            categoryId: advertCategoryId,
        });
    }, [topInformation, additionalInformation, links, t, advertCategoryId]);

    const defaultMessage = askForMissingInfo
        ? askForInfoMessage
        : getDefaultMessage({
              advertId: `${advertId}`,
              categoryId: advertCategoryId,
              openDay,
              t,
          });

    const defaultMessageSubject = useMemo(() => {
        if (!shouldDisplayPredefinedContactMessages) {
            return null;
        }

        if (askForMissingInfo || !suggestPrice) {
            return MESSAGE_SUBJECT_OPTIONS[0];
        }

        return MESSAGE_SUBJECT_OPTIONS[1];
    }, [askForMissingInfo, suggestPrice, shouldDisplayPredefinedContactMessages]);

    const [characterCount, setCharacterCount] = useState(defaultMessage?.length || 0);

    const defaultValues = shouldPrefillFormWithRecentData
        ? {
              [FORM_FIELD.text]: defaultMessage,
              [FORM_FIELD.email]: contactFormPrefillData?.email || '',
              [FORM_FIELD.name]: contactFormPrefillData?.name || '',
              [FORM_FIELD.phone]: contactFormPrefillData?.phone || '',
              [FORM_FIELD.messageSubject]: defaultMessageSubject,
              [FORM_FIELD.financeLead]: false,
          }
        : {
              [FORM_FIELD.text]: defaultMessage,
              [FORM_FIELD.email]: user?.email || '',
              [FORM_FIELD.name]: '',
              [FORM_FIELD.phone]: '',
              [FORM_FIELD.messageSubject]: defaultMessageSubject,
              [FORM_FIELD.financeLead]: false,
          };

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitted, isValid, touchedFields },
        reset,
        control,
        setValue,
        clearErrors,
        setFocus,
        trigger,
    } = useForm<FormValues>({
        defaultValues,
        mode: 'onTouched',
    });

    const isFinanceLeadChecked = useWatch({
        control,
        name: FORM_FIELD.financeLead,
    });

    const messageSubject = useWatch({
        control,
        name: FORM_FIELD.messageSubject,
    });

    const isMessageFieldDisabled = shouldDisplayPredefinedContactMessages && !messageSubject;
    const isPhoneFieldRequired = isFinanceLeadChecked || advertSource === 'urn:partner:obidointegration';
    const isTextFieldRequired =
        (shouldDisplayPredefinedContactMessages && !!messageSubject) || !shouldDisplayPredefinedContactMessages;

    // set text value for AskAboutPrice
    useEffect(() => {
        if (askAboutPrice) {
            setValue(FORM_FIELD.text, t('frontend.ad.contact-form.ask-about-price'));
        }
    }, [askAboutPrice, setFocus, setValue, t]);

    useEffect(() => {
        if (!shouldDisplayPredefinedContactMessages) {
            return;
        }

        const message =
            askForMissingInfo && messageSubject?.value === MESSAGE_SUBJECT.moreInfo
                ? askForInfoMessage
                : getMessageBySubject(advertCategoryId, messageSubject?.value, t);

        setCharacterCount(message.length);
        setValue(FORM_FIELD.text, message);
        clearErrors(FORM_FIELD.text);
    }, [
        messageSubject,
        setValue,
        clearErrors,
        defaultMessage,
        t,
        shouldDisplayPredefinedContactMessages,
        askForInfoMessage,
        askForMissingInfo,
        advertCategoryId,
    ]);

    useEffect(() => {
        if (!shouldListenForSuggestPriceEvent) {
            return;
        }

        listenForPlatformEvent(AD_PAGE_EVENT.suggestPrice, (): void => {
            setValue(FORM_FIELD.messageSubject, MESSAGE_SUBJECT_OPTIONS[1]);
            setTimeout(() => setFocus(FORM_FIELD.text), 0);
        });

        return (): void => {
            unmountPlatformEvent(AD_PAGE_EVENT.suggestPrice, () => setValue(FORM_FIELD.messageSubject, null));
        };
    }, [setValue, setFocus, shouldListenForSuggestPriceEvent]);

    const handleMessageSubjectChange = (
        option: SingleValue<MessageSubjectDropdownOption>,
        field: ControllerRenderProps<FormValues, typeof FORM_FIELD.messageSubject>,
    ): void => {
        field.onChange(option);
        messageDetailParam.current = 'changed_predefined_message';
        if (option?.value) {
            trackEvent('message_type_selected', {
                touch_point_button: 'predefined-message-subjects',
                message_type: option.value,
                ...additionalTrackingData,
            });
        }
    };

    useEffect(() => {
        if (touchedFields.phone) {
            trigger(FORM_FIELD.phone);
        }
    }, [isFinanceLeadChecked, touchedFields, trigger]);

    const touchPointButton = getTrackingTouchPoint({
        isAskAboutPriceClicked: askAboutPrice,
        contactFormVariant: variant,
    });

    const trackingData = getTrackingData(touchPointButton, messageSubject?.value, additionalTrackingData);

    const { onFormSubmit, fetching, lastSuccessfulSubmit } = useSubmitForm({
        handleSubmit,
        reset,
        trackingData,
        messageDetail: messageDetailParam.current,
        shouldDisplayPredefinedContactMessages,
        advertId,
        cityName,
        isModal,
        defaultMessage,
        setCharacterCount,
        onSubmit,
    });

    const privacyPolicyKey = 'frontend.ad.contact-form.gdpr-privacy-policy-new';

    const {
        [FORM_FIELD.name]: { message: nameError } = EMPTY_ERROR,
        [FORM_FIELD.phone]: { message: phoneError } = EMPTY_ERROR,
        [FORM_FIELD.email]: { message: emailError } = EMPTY_ERROR,
        [FORM_FIELD.text]: { message: textError } = EMPTY_ERROR,
        [FORM_FIELD.messageSubject]: { message: messageSubjectError } = EMPTY_ERROR,
    } = errors;

    const onTextAreaChange = (event: FormEvent<HTMLTextAreaElement>): void => {
        const currentCount = (event.target as HTMLTextAreaElement).value.length;
        messageDetailParam.current = 'custom_message';

        setCharacterCount(currentCount);
    };

    const getTouchPointForPhoneNumber = useCallback(
        (touchPoint: string): string => {
            if (askAboutPrice) {
                return CONTACT_FORM_TOUCH_POINT_BUTTON.askAboutPrice;
            }

            if (touchPoint === 'countryCode') {
                return CONTACT_FORM_TOUCH_POINT_BUTTON.countryCode;
            }

            if (variant === 'default' || variant === 'aside') {
                return CONTACT_FORM_TOUCH_POINT_BUTTON.phoneNumber;
            }

            return touchPointButton;
        },
        [askAboutPrice, touchPointButton, variant],
    );

    const { onBlurSplitInput, onFocusSplitInput } = useMemo<
        Pick<PhoneInputProps, 'onBlurSplitInput' | 'onFocusSplitInput'>
    >(() => {
        return {
            onBlurSplitInput: ({ isCountryCodeValid, isPhoneNumberValid, touchPoint }): void => {
                const isFieldValid = touchPoint === 'countryCode' ? isCountryCodeValid : isPhoneNumberValid;

                trackEvent(`reply_message_${FORM_FIELD.phone}_${isFieldValid ? 'valid' : 'error'}`, {
                    touch_point_button: getTouchPointForPhoneNumber(touchPoint),
                    ...additionalTrackingData,
                });
            },
            onFocusSplitInput: (touchPoint): void => {
                trackEvent(`reply_message_${FORM_FIELD.phone}_start`, {
                    touch_point_button: getTouchPointForPhoneNumber(touchPoint),
                    ...additionalTrackingData,
                });
            },
        };
    }, [getTouchPointForPhoneNumber, trackEvent, additionalTrackingData]);

    return (
        <Form
            aria-label={t('frontend.ad.contact-form.form-label')}
            onSubmit={onFormSubmit}
            noValidate
            isContactFormBottom={isContactFormBottom}
            isMessageSubjectDropdownDisplayed={shouldDisplayPredefinedContactMessages}
            isFinanceLeadCheckboxDisplayed={shouldDisplayFinanceLeadCheckbox}
        >
            <LastContact adId={advertId} lastSuccessfulSubmit={lastSuccessfulSubmit} />
            <NameField error={nameError}>
                <Input
                    aria-label={t('frontend.ad.contact-form.field-placeholder-name')}
                    placeholder={`${t('frontend.ad.contact-form.field-placeholder-name')}*`}
                    maxLength={100}
                    type="text"
                    {...trackInputEvent({
                        errors,
                        registerValue: register(FORM_FIELD.name, {
                            minLength: {
                                value: 1,
                                message: t(ERROR_DETAIL.nameRequired),
                            },
                            required: t(ERROR_DETAIL.nameRequired),
                        }),
                        trackEvent,
                        trackingData,
                    })}
                    variant={nameError ? 'invalid' : undefined}
                />
            </NameField>
            <EmailField error={emailError}>
                <Input
                    aria-label={t('frontend.ad.contact-form.field-placeholder-email')}
                    placeholder={`${t('frontend.ad.contact-form.field-placeholder-email')}*`}
                    type="email"
                    {...trackInputEvent({
                        errors,
                        registerValue: register(FORM_FIELD.email, {
                            pattern: {
                                message: t(ERROR_DETAIL.email),
                                value: EMAIL_REGULAR_EXPRESSION,
                            },
                            required: t(ERROR_DETAIL.emailRequired),
                        }),
                        trackEvent,
                        trackingData,
                    })}
                    disabled={isAwaitingUserData || !!user?.email}
                    variant={emailError ? 'invalid' : undefined}
                />
            </EmailField>
            <PhoneField error={phoneError}>
                <PhoneInput
                    isCountryCodeEnabled
                    isValid={!phoneError}
                    onBlurSplitInput={onBlurSplitInput}
                    onFocusSplitInput={onFocusSplitInput}
                    phoneNumberLabelTranslationKey="frontend.ad.contact-form.field-placeholder-phone"
                    placeholder={`${t('frontend.ad.contact-form.field-placeholder-phone')}${
                        isPhoneFieldRequired ? '*' : ''
                    }`}
                    {...register(
                        FORM_FIELD.phone,
                        createPhoneInputRegisterValidators(t, { required: isPhoneFieldRequired }),
                    )}
                    required={isPhoneFieldRequired}
                />
            </PhoneField>
            {shouldDisplayPredefinedContactMessages ? (
                <MessageSubjectField error={messageSubjectError}>
                    <Controller
                        rules={{ required: t(ERROR_DETAIL.messageSubjectRequired) }}
                        control={control}
                        name={FORM_FIELD.messageSubject}
                        render={({ field }): JSX.Element => (
                            <Dropdown
                                {...field}
                                placeholder={t('frontend.ad.contact-form.field-placeholder-message-subject')}
                                aria-label={t('frontend.ad.contact-form.field-placeholder-message-subject')}
                                options={MESSAGE_SUBJECT_OPTIONS}
                                isClearable={false}
                                isSearchable={false}
                                variant={messageSubjectError ? 'invalid' : 'default'}
                                dataCy="contact-form.message-subject-dropdown"
                                onChange={(option): void => handleMessageSubjectChange(option, field)}
                            />
                        )}
                    />
                </MessageSubjectField>
            ) : null}
            <TextAreaField error={textError}>
                <StyledTextArea
                    aria-label={t('frontend.ad.contact-form.field-text')}
                    maxLength={TEXT_AREA_MAX_LENGTH}
                    {...trackInputEvent({
                        errors,
                        registerValue: register(FORM_FIELD.text, {
                            required: {
                                value: isTextFieldRequired,
                                message: t(ERROR_DETAIL.textRequired),
                            },
                        }),
                        trackEvent,
                        trackingData,
                    })}
                    onInput={onTextAreaChange}
                    variant={textError ? 'invalid' : 'default'}
                    placeholder={t('frontend.ad.contact-form.field-placeholder-text')}
                    disabled={isMessageFieldDisabled}
                />
                <TextAreaCounter
                    data-cy="contact-form.text-area-counter"
                    data-testid="text-area-counter"
                >{`${characterCount} / ${TEXT_AREA_MAX_LENGTH}`}</TextAreaCounter>
            </TextAreaField>
            <StyledGdprNote
                data-cy="contact-form.gdpr-note"
                intro={t('frontend.ad.contact-form.gdpr-administrator')}
                details={transformGdprDetails({
                    description: t(privacyPolicyKey),
                    privacyPolicyLink: SITE_CONFIG.privacyPolicyLink,
                    termsAndConditionsLink: SITE_CONFIG.termsAndConditionsLink,
                })}
                shouldUsePopover={!shouldDisplayGdprInline && !isContactFormBottom}
            />
            {shouldDisplayFinanceLeadCheckbox && (
                <FinanceLeadBox
                    shouldDisplayGdprInline={shouldDisplayGdprInline}
                    formVariant={variant}
                    register={register}
                    isFinanceLeadChecked={isFinanceLeadChecked}
                />
            )}
            <SubmitButton
                data-cy="contact-form.submit-button"
                type="submit"
                variant={ButtonVariant.Primary}
                disabled={(!isValid && isSubmitted) || fetching}
                shouldFitContainer
                isContactFormBottom={isContactFormBottom}
            >
                {t('frontend.ad.contact-form.send-message')}
            </SubmitButton>
        </Form>
    );
};
