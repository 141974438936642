import styled from '@emotion/styled';
/* eslint-disable import/no-restricted-paths -- @FIXME: Nexus tokens should be used in widgets */
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LINE_HEIGHT, SIZE, WEIGHT } from '@lib/styles/partials/typography';
/* eslint-enable import/no-restricted-paths */
import type { CSSProperties } from 'react';

import { CONTACT_FORM_WIDGET_THEME } from '../../../../theme/[[[SITECODE]]]';

export const FinanceLeadBoxWrapper = styled.div`
    grid-area: financeLead;
`;

export const FinanceLeadField = styled.div`
    display: flex;
    align-items: center;
    font-size: ${SIZE.p4};
`;

export const FinanceLabel = styled.label`
    margin: 0 auto 0 8px;
    color: ${CONTACT_FORM_WIDGET_THEME.contactFormWrapper.financeLeadLabel.color};

    > svg {
        margin-left: 4px;
    }
`;

export const FinanceLeadHeader = styled.div`
    padding-bottom: 12px;
    font-weight: ${WEIGHT.semibold};
`;

export const FinanceLeadContainer = styled.div`
    padding: 16px;
    border-radius: 4px;
    background: ${CONTACT_FORM_WIDGET_THEME.contactFormWrapper.financeLeadLabel.backgroundColor};

    > ${FinanceLeadField} {
        align-items: start;
    }
`;

export const PrivacyPolicyParagraph = styled.p`
    margin: 8px 0;
    white-space: pre-line;

    &:first-of-type {
        margin-top: 0;
    }
`;

export const InlineGdpr = styled.div`
    width: 100%;
    padding: 8px;
    opacity: 0.8;
    color: ${CONTACT_FORM_WIDGET_THEME.contactFormWrapper.tooltip.default.color};
    font-size: ${SIZE.p4};
    line-height: ${LINE_HEIGHT.x1};
    white-space: pre-line;
    > p {
        margin: 0;
    }
`;

export const WithTooltip = styled.div`
    position: relative;

    @media (min-width: ${BREAKPOINT.lg}) {
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: -16px;
            left: -9px;
            width: 0;
            height: 0;
            transform: translateY(-50%);
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid;
            border-left-color: ${CONTACT_FORM_WIDGET_THEME.contactFormWrapper.withTooltip.borderColor};
        }
    }
`;

export const Tooltip = styled.aside<{ isFixedWidth?: boolean }>`
    padding: 8px;
    font-size: ${SIZE.p4};
    line-height: ${LINE_HEIGHT.x1};

    @media (min-width: ${BREAKPOINT.lg}) {
        position: absolute;
        top: -30px;
        left: 0;
        width: ${({ isFixedWidth }): CSSProperties['width'] => (isFixedWidth ? '460px' : 'fit-content')};
        transform: translateX(calc(-100% - 8px));
        animation: 0.5s ease 0s 1 normal none running fadein;
        background-color: ${CONTACT_FORM_WIDGET_THEME.contactFormWrapper.tooltip.desktop.backgroundColor};
        box-shadow: ${CONTACT_FORM_WIDGET_THEME.contactFormWrapper.tooltip.desktop.boxShadow};
        color: ${CONTACT_FORM_WIDGET_THEME.contactFormWrapper.tooltip.desktop.color};
        text-align: left;
    }
`;

export const PrivacyPolicyLink = styled.a<{ shouldDisplayGdprInline: boolean }>`
    color: ${CONTACT_FORM_WIDGET_THEME.contactFormWrapper.tooltip.default.color};
    font-weight: ${WEIGHT.semibold};

    @media (min-width: ${BREAKPOINT.lg}) {
        color: ${({ shouldDisplayGdprInline }): CSSProperties['color'] =>
            shouldDisplayGdprInline
                ? CONTACT_FORM_WIDGET_THEME.contactFormWrapper.tooltip.default.color
                : CONTACT_FORM_WIDGET_THEME.contactFormWrapper.tooltip.desktop.color};
    }
`;
